import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Google from "../components/google 1.svg";
import Header from "../components/header";
import request from "../core/request";
import { encrypt, getDevAppLink } from "../utils";
import { env } from "../utils/const";
import { validateEmail, validatePassword } from "../utils/validator";
const { NODE_ENV } = process.env;
const Login = () => {
  const { REACT_APP_AUTH_PAGE_URL } = env;
  const { REACT_APP_STATIC_PAGE_URL } = env;
  const { REACT_APP_GOOGLE_AUTH_URL } = env;
  const { REACT_APP_GOOGLE_CLIENT_ID } = env;

  const [state, setState] = useState({
    email: "",
    emailError: "",
    password: "",
    passwordError: "",
    checkError: "",
    showPassword: false,
  });
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const history = useHistory();
  const handleState = (value, key) => {
    setState({ ...state, [key]: value, [key + "Error"]: "" });
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");

  useEffect(() => {
    const getCodeFromURL = async () => {
      if (code) {
        let { data, status } = await request.post(`/user/auth`, {
          code: code,
        });
        if (status > 400) {
          toast.error(data?.message);
          setTimeout(function () {
            window.location.href = REACT_APP_AUTH_PAGE_URL;
          }, 5000);
        }
        if (status >= 200 && status < 400) {
          let url = data?.data?.reDirectUrl;
          window.location.href = url;
        }
      } else {
        console.log("Code parameter not found in the URL");
      }
    };

    getCodeFromURL();
  }, [
    location?.search,
    code,
    REACT_APP_AUTH_PAGE_URL,
    REACT_APP_STATIC_PAGE_URL,
  ]);
  const prevPassWordRef = useRef();
  const prevEmailRef = useRef();
  useEffect(() => {
    //assign the ref's current value to the count Hook
    prevPassWordRef.current = state.password;
    prevEmailRef.current = state.email;
  }, [state.password, state.email]);

  const handleLogin = async () => {
    let isValid = true;
    let localState = state;
    const emailValidation = validateEmail(state.email);
    let isValidEmail;
    let isValidPassword;
    let isChecked;
    if (!emailValidation.isValid) {
      isValid = false;
      isValidEmail = true;
    }

    const passwordValidation = validatePassword(state.password);
    if (!passwordValidation.isValid) {
      isValid = false;
      isValidPassword = true;
    }
    if (!check) {
      isValid = false;
      isChecked = true;
    }

    setState({
      ...state,
      emailError: isValidEmail ? emailValidation?.message : "",
      passwordError: isValidPassword ? passwordValidation?.message : "",
      checkError: isChecked ? "Please Agree the Terms & Conditions" : "",
    });

    if (isValid) {
      if (!state.emailError?.length > 0 && !state.passwordError?.length) {
        setLoading(true);
        const hashedPassword = await encrypt(state.password);
        let { data, status } = await request.post(`/user/signin`, {
          email: state.email,
          password: hashedPassword,
        });
        if (status >= 400) {
          if (data.message.includes("password")) {
            setState({
              ...state,
              passwordError: data.message,
            });
          }
          if (data.message.includes('body/email must match format "email"')) {
            setState({
              ...state,
              emailError: "Enter valid email Id.",
            });
            toast.error("Enter valid email Id.");
          } else if (data.message.includes(state.email)) {
            toast.error(data.message);
            setState({
              ...state,
              emailError: data.message,
            });
          } else {
            toast.error(data.message);
          }
        }
        if (status >= 200 && status < 300) {
          let redirectionLink = data?.data?.redirectLink;
          if (NODE_ENV === "development") {
            let link = getDevAppLink(redirectionLink);
            if (link) {
              redirectionLink = link;
            }
          }
          toast.success("Successfully signed in, redirecting...");
          window.open(redirectionLink, "_self");
        }
        setLoading(false);
      } else {
        setState(localState);
      }
    }
  };

  const onChangeChecked = () => {
    setState({ ...state, checkError: "" });
    setCheck(!check);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleGoogleSignInClick = () => {
    const authorizationUrl =
      REACT_APP_GOOGLE_AUTH_URL +
      `?client_id=${REACT_APP_GOOGLE_CLIENT_ID}` +
      `&redirect_uri=${REACT_APP_AUTH_PAGE_URL}` +
      "&response_type=code" +
      "&scope=openid%20email%20profile%20phone";

    window.location.href = authorizationUrl;
  };

  return (
    <div className="flex flex-column">
      <Header />
      <div className="flex flex-center">
        <div className="flex card">
          <div>
            <div>
              <div className="container">
                <label className="label" htmlFor="email">
                  Email Id *
                </label>
                <input
                  type="email"
                  placeholder="eg: example@mail.com"
                  name="email"
                  className="input"
                  value={state.email}
                  onChange={({ target }) => {
                    handleState(target.value, "email");
                  }}
                  onKeyPress={handleKeyPress}
                />
                <div className="error">{state?.emailError}</div>
                <label className="label" htmlFor="psw">
                  Password *
                </label>
                <div className="input-wrapper">
                  <Input.Password
                    name="psw"
                    placeholder="＊＊＊＊＊＊＊＊"
                    className="input"
                    value={state.password}
                    onChange={({ target }) => {
                      handleState(target.value, "password");
                    }}
                    onKeyPress={handleKeyPress}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </div>

                <div className="error">{state.passwordError}</div>
                <div className="checkbox">
                  <input
                    id="checked"
                    name="checked"
                    className="checked"
                    type="checkbox"
                    onClick={onChangeChecked}
                    value={check}
                  />
                  <label htmlFor="checked" className="labelcheck">
                    {" "}
                    Agree to all the{" "}
                  </label>
                  <a
                    className="Tearms"
                    href={"https://www.pluginlive.com/Terms_And_Conditions"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    Terms & Conditions
                  </a>
                </div>
                <div className="error">{state.checkError}</div>

                <button
                  onClick={handleLogin}
                  className="button"
                  disabled={loading}
                >
                  Login
                </button>
              </div>
            </div>
            <div className="forgot-button">
              <div
                onClick={() => {
                  history.push("/forgot");
                }}
              >
                Forgot Password?
              </div>
            </div>

            <div className="forgot-button1">
              <button
                onClick={handleGoogleSignInClick}
                className="google"
                id="google-signin-button"
                disabled={loading}
              >
                <div className="button-content">
                  <img src={Google} alt="Google Logo" />
                  <span>Login with Google</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
